<template>
  <div class="user">
    <div class="tit">{{ $t('reg_identityVerification_1') }}</div>
    <div class="hint">{{ $t('reg_identityVerification_2') }}！</div>
    <div class="user-box">
      <div class="qrcode" ref="qrCodeUrl"></div>
      <el-popover
        placement="top"
        :title="$t('reg_identityVerification_3')"
        width="300"
        trigger="click"
        :content="$t('reg_identityVerification_4')"
      >
        <el-button type="text" slot="reference" style="width: 100%">
          <i class="el-icon-question"></i>{{ $t('reg_identityVerification_5') }}
        </el-button>
      </el-popover>
    </div>
    <div class="textCenter">
      <el-button @click="next" type="primary">
        {{ $t('reg_identityVerification_6') }}
      </el-button>
      <el-button @click="back" type="text">{{
        $t('reg_identityVerification_7')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'Email',
  data() {
    return {
      RwmBody: '',
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: localStorage.getItem('sm_email'),
      },
      nextVerify: '',
      type: '',
    }
  },
  mounted() {
    const email = localStorage.getItem('sm_email')
    this.RwmBody = `https://test.bjqiju.com/#/h5/guidance?email=${email}`
    console.log(this.RwmBody)
    this.type = JSON.parse(localStorage.getItem('sm_type'))
    this.creatQrCode()
    this.IetVerify()
  },
  methods: {
    creatQrCode() {
      let that = this
      new QRCode(this.$refs.qrCodeUrl, {
        text: that.RwmBody, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },
    back() {
      let url = ''
      if (this.type === 1) {
        url = 'MainlandUser'
      } else if (this.type === 2) {
        url = 'OrganizationUser'
      } else if (this.type === 3) {
        url = 'OverseasUser'
      }
      this.$router.push({ name: url })
    },
    next() {
      this.userVerify(true)
    },
    IetVerify() {
      this.nextVerify = setInterval(() => {
        this.userVerify()
      }, 5000)
    },
    async userVerify(flag) {
      let res = await this.$axios.post('/api/Common/getUser', {
        email: this.ruleForm.email,
      })
      if (res.code === 1 && res.msg) {
        this.$router.push({ name: 'BankInfo' })
      } else {
        if (flag) {
          this.$message({
            message: '请先在手机端上传护照',
            type: 'warning',
          })
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.nextVerify)
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 400px;
  margin: 80px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 10px;
    text-align: center;
  }
  .hint {
    font-size: 12px;
    color: #666666;
    margin-bottom: 30px;
    text-align: center;
  }
  .el-icon-question {
    margin-right: 4px;
  }
}
.qrcode {
  border-radius: 8px;
  border: 1px solid #999999;
  padding: 10px;
  width: 170px;
  margin: 10px auto;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
</style>
